<template>
    <div class="main-content" style="
      padding-bottom: 10vh !important
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 100%;
        background-position: center;
      ">
        <div class="dashboard-banner position-relative">
            <div class="d-flex justify-content-center align-items-end">
              <!-- <h4 class="mt-5 mx-4 text-center text-primary">{{ $t("dashboard") }}</h4> -->
            </div>
            <!-- <div class="position-absolute h-100 w-100" style="background: #d2d2d24d" v-if="isLoading">
            <div class="px-2 py-1 rounded" style="
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #add8e6;
                ">
            </div>
            </div> -->
        </div>
        <div class="m-3 mx-3 mt-4">
            <div class="header">
                <img src="../../../assets/images/etnex_buy/back_icon.png" alt="Back" class="back-arrow-icon" @click="goBack">
                <h1 class="main_text text text_main_title">{{ $t("record") }}</h1>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-12" style="padding-bottom: 1rem;">
                    <div class="card">
                        <div class="card-container-1 black-dark-blue-card" :class="backgroundClass" style="margin-top: -80px;">
                            <div class="card-content" style="flex-direction: column !important;">
                            <img :src="imageSrc" class="card-icon mr-3">
                            <span class="card-title">{{ cardTitle }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="scrollable-container">
            <div v-if="dataList.length > 0" style="padding: 15px;">
              <div class="notification-card px-3 py-3" v-for="item in dataList" :key="item.id">
                  <div class="row align-items-center">
                      <div class="col-6">
                          <p class="mb-0 title text"> {{ $i18n.locale == "zh" ? item.detail : item.detailen }} </p>
                          <span class="date text" style="letter-spacing: normal; color: rgb(127, 127, 127);">
                            
                              <div class="mr-2 text-white" style="display: inline-block;">
                                <i class="fa fa-calendar" aria-hidden="true"></i> {{ getDate(item.created_at) }}
                              </div>
                          </span>
                      </div>
                      <div class="text-right text-light-green font-weight-bold col-6">
                          <p class="text text_14 weight_600 mb-0" style="line-height: 24px;" v-if="bonusType === 'static_bonus'">
                            {{ item.wallet2 }} ETNEX
                          </p>
                          <p class="text text_14 weight_600 mb-0" style="line-height: 24px;" v-else>
                            {{ item.wallet1 }} USDT
                            <br>
                            {{ item.wallet2 }} ETNEX
                          </p>
                      </div>
                  </div>
              </div>
            </div>
            <h3 v-else class="no-data-text text">
              {{ $t("no_data") }}
            </h3>
            <div class="purchase-statement-container" style="text-align: center; padding-top: 10px;" v-if="moreRecords">
                <button class="make-purchase-button" style="width: 40% !important;" @click="loadMore">
                    {{ $t("load_more") }}
                </button>
            </div>
          </div>
        <Dialog ref="msg"></Dialog>
    </div>
  </template>
  
  <script>
  import { getUserBonusRecord } from "../../../system/api/api";
  import { handleError } from "../../../system/handleRes";
  import Dialog from "../../../components/dialog.vue";
  import { mapGetters } from "vuex";
  export default {
    computed: {
      ...mapGetters(["lang"]),
      imageSrc() {
        switch (this.bonusType) {
          case 'sponsor_bonus':
            // return require('../../../assets/images/latrader_bonus/one.png');
            return "";
          case 'dynamic_bonus':
            // return require('../../../assets/images/latrader_bonus/two.png');
            return "";
          case 'static_bonus':
            // return require('../../../assets/images/latrader_bonus/three.png');
            return "";
          default:
            return "";
        }
      },
      cardTitle() {
        switch (this.bonusType) {
          case 'sponsor_bonus':
            return this.$t('sponsor_bonus');
          case 'dynamic_bonus':
            return this.$t('dynamic_bonus');
          case 'static_bonus':
            return this.$t('static_bonus');
          default:
            return "";
        }
      },
      backgroundClass() {
        switch (this.bonusType) {
          case 'sponsor_bonus':
            return 'back_one';
          case 'dynamic_bonus':
            return 'back_two';
          case 'static_bonus':
            return 'back_three';
          default:
            return "";
        }
      },
    },
    
    components: {
      Dialog,
    },
    data() {
        return {
        isLoading: true,
        dataList: [],
        currentPage: 1,
        lastPage: '',
        language: "",
        bonusType: "",
        moreRecords: "",
        etnex: "ETNEX",
        usdt: "USDT",
      };
    },
    props: ["success"],
    methods: {
    getDate(dateTime) {
        const date = new Date(dateTime);
        const datePart = date.toLocaleDateString('en-US');
        return datePart;
    },

    goBack() {
        this.$router.go(-1)
    },

    getTime(dateTime) {
      const date = new Date(dateTime);
      const timePart = date.toLocaleTimeString('en-US', { hour12: false });
      return timePart;
    },

    loadMore() {
        this.currentPage++;
        this.loadItems();
    },

    loadItems() {
        var result = getUserBonusRecord(this.bonusType, this.currentPage);
        var self = this;
        this.isLoading = true;
        result
            .then(function (value) {
            // var _data = value.data.data;
            // var _list = _data.record.data;
            // console.log(_data.record);
            // alert(_data.record.last_page);
            // self.currentPage += 1;
            // self.lastPage = _data.record.last_page;

            // console.log(self.lastPage);
            // for (let i = 0; i < _list.length; i++) {
            //     self.dataList.push(_list[i]);
            // }

            self.dataList = self.dataList.concat(value.data.data.record.data);
            if (!value.data.data.record.next_page_url) {
              self.moreRecords = false;
            }else{
              self.moreRecords = true;
            }

            // alert(self.record.last_page);
            self.isLoading = false;
            })
            .catch(function (error) {
            self.isLoading = false;
            self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
            });
    },
    },
    created() {
        const bonusType = this.$route.query.type;
        this.bonusType = bonusType;
        this.loadItems();
    },
  };
  </script>
  
  <style scoped>
  .gx-1 {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gx-1 > .col,
  .gx-1 > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  
  
  .card-container-1 {
    position: relative;
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
    backdrop-filter: blur(20px);
    background: linear-gradient(90deg, #152331 0%, #000000 100%);
    
  }
  
  .card-content {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .card-icon {
    width: 65px;
    height: auto;
  }
  
  .card-title {
    margin: 0;
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }
  
  
  .header {
      display: flex;
      margin-top: 4rem !important;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 100px !important;
    }
    

    
    .text_main_title{
      flex-grow: 1;
      text-align: center;
    }
  
    .text{
      color: var(--Main, #FFF);
      font-family: Poppins;
      font-style: normal;
    }
    
    .main_text{
      font-size: 20px;
      font-weight: 600;
      line-height: 34px;
      margin-top: 7px;
    }

    .notification-card {
      align-items: center;
      background: linear-gradient(90deg, #152331 0%, #000000 100%);
      color: #fff;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 7px;
    }


  .title {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
  }

  .button-group .btn-timeframe {
      border: none;
      background-color: #7E8088;
      color: #222223!important;
      margin-left: 0.25rem;
      border-radius: 8px;
      box-shadow: none;
      transition: background-color 0.3s;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      line-height: 26px;
      height: 26px;
      padding: 0 1rem;
  }

/* text css */
.text_main_title{
  flex-grow: 1;
  text-align: center;
}

.no-data-text{
  font-weight: 400;
  font-size: 16px;
  flex-grow: 1;
  text-align: center;
  margin-top: 40px;
  color: var(--Secondary, #7E8088) !important;
}
.text{
  color: var(--Main, #FFF);
  font-family: Poppins;
  font-style: normal;
}

.main_text{
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
  margin-top: 7px;
}
.weight_600{
  font-weight: 600;
}

.text_14{
  font-size: 14px;
}

/* text css end */

.header {
  display: flex;
  /* padding: 0px 16px; */
  margin-top: 4rem !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

  
  </style>